import {IOrgFunction, IUserModel} from "../api/models";
import {useParams} from "react-router";

export enum EForms {
    memberships = "memberships",
    members = "members",
    kyu = "kyu",
    licences = "licences",
    events = "events",
    organizations = "organizations",
    trainer = "trainer",
    notifications = "notifications",
}

/**
 * Retrieves the user's role based on the provided user.
 *
 * @param {IUserModel} user - The user object.
 * @return {IOrgFunction} - The user's role.
 */
export function useParamRole(user: IUserModel): IOrgFunction {
    const params = useParams();
    const roleId = Number(params['role'] || 0)

    return user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;
}

export function useParamOrg() {
    const params = useParams();
    return Number(params['org'] || 0);
}

export function useParamForm(): EForms {
    const params = useParams();
    const form = params['form']
    return form as EForms
}


/**
 * Retrieves the value of the "id" parameter from the current URL and
 * returns it as a number. If the "id" parameter is not found in the URL,
 * it returns zero.
 *
 * @returns {number} The value of the "id" parameter as a number, or zero
 * if the "id" parameter is not found in the URL.
 */
export  function useParamId(): number|null {
    const params = useParams();
    return params["id"] == null ? null : Number(params["id"] || 0)
}
