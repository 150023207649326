import React, {useEffect, useMemo, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {useParamId, useParamRole, useParamOrg} from "../../useParam";
import {IMember, IRole} from "../../../api/models/MemberModel";
import {MemberService} from "../../../api/member";
import {Button, DateInput, DTS, DSPg, FD, FDS, FDX, FDXS, Form, Item, Select} from "../../form";
import {Row} from "../../form/row";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {toaster} from "../../../core/toaster";
import styles from "./member_detail.module.scss";
import { getOrgLevelFromId } from '../../../util/roles';
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export const MemberDetailRoles: React.FC<IPageLinkProps> = ({user, profile, refresh, setRefresh}) => {
    const role = useParamRole(user);
    const orgId = useParamOrg();
    const id = useParamId(); // mg_id
    const [member, setMember] = useState<IMember | null>();
    const [roles, setRoles] = useState<IRole[]>([]);
    const [rolesExpired, setRolesExpired] = useState<IRole[]>([]);

    const [newDateFrom, setNewDateFrom] = useState<Date|null>(new Date(Date.now()));
    const [newDateTo, setNewDateTo] = useState<Date | null>(null);
    const [terminateDate, setTerminateDate] = useState<Date | null>(null);
    const [newType, setNewType] = useState();
    const [newOrg, setNewOrg] = useState(0);
    const [changeOrg, setChangeOrg] = useState(0);
    const [roleEndDates, setRoleEndDates] = useState<{[key: string]: Date | null}>({});

    const OJV = 1;

    interface RoleFilterConfig {
        whitelist?: string[];
        blacklist?: string[];
    }

    const convertWildcardToRegExp = (pattern: string): RegExp => {
        // Escape special RegExp characters except %
        const escaped = pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        // Convert SQL-like % wildcards to RegExp .*
        const regexPattern = escaped.replace(/%/g, '.*');
        // Create case-insensitive RegExp that matches entire string
        return new RegExp(`^${regexPattern}$`, 'i');
    };

    const isMatch = (value: string, pattern: string): boolean => {
        if (pattern.includes('%')) {
            return convertWildcardToRegExp(pattern).test(value);
        }
        return value.toLowerCase() === pattern.toLowerCase();
    };

    const filterRolesByGroup = (
        roles: IRole[] | undefined,
        config: RoleFilterConfig = {}
    ): IRole[] => {
        if (!roles) return [];

        const { whitelist = [], blacklist = [] } = config;

        return roles.filter(role => {
            const groupString = role.group?.String || '';

            // Check if role is in blacklist (case-insensitive, with wildcard support)
            const isBlacklisted = blacklist.some(pattern =>
                isMatch(groupString, pattern)
            );
            if (isBlacklisted) return false;

            // If whitelist is not empty, only show roles in whitelist
            if (whitelist.length > 0) {
                return whitelist.some(pattern =>
                    isMatch(groupString, pattern)
                );
            }

            // If no whitelist, show all roles not in blacklist
            return true;
        });
    };

    useEffect(() => {
        MemberService.get(role.orgId, id||0).then((m) => {
            setMember(m);
        });
    }, [id, role, refresh]);


    useEffect(() => {
        MemberService.roles(role.orgId, id||0).then((r) => {
            setRoles(r);
        });
    }, [id, role, refresh]);

    useEffect(() => {
        MemberService.rolesExpired(role.orgId, id||0).then((r) => {
            setRolesExpired(r);
        });
    }, [id, rolesExpired, refresh]);

    useEffect(() => {
        setNewOrg(role.orgId)
    }, [role]);

    // const roleGroups =  useMemo(() => profile.roleGroups.sort((a,b) => Number(a) - Number(b)).map(x => ( x.roles.map(y => ({...y, group: x.groupName})))).flat()
    //         .filter(x => x.editWhitelist ? x.editWhitelist.includes(role.orgId) : x.editWhitelist == null)
    //         .map(x =>  ({
    //             label: `${x.group} | ${x.name}`,
    //             value: x.id
    //         }))
    //     , [profile, role]);
    const roleGroups = useMemo(() => {
        // Get the current organization's level from the selected org ID (newOrg)
        const currentOrgLevel = getOrgLevelFromId(newOrg);
        // console.log('##currentOrgLevel##', currentOrgLevel);
        // console.log('##newOrg##', newOrg);
        // console.log('##profile.roleGroups##', profile.roleGroups);

        const result = profile.roleGroups
            .sort((a, b) => Number(a) - Number(b))
            .map(x => x.roles.map(y => ({...y, group: x.groupName})))
            .flat()
            .filter(x => {
                // Check if the current user's org has permission to edit/assign this role
                const userOrgHasPermission = x.editWhitelist ?
                    x.editWhitelist.includes(role.orgId) :
                    x.editWhitelist == null;

                // Check if the selected organization is explicitly blacklisted
                const notBlacklisted = x.orgBlacklist ?
                    !x.orgBlacklist.includes(newOrg) :
                    true;

                // If orgWhitelist exists, selected org MUST be in it
                const whitelistOk = x.orgWhitelist ?
                    x.orgWhitelist.includes(newOrg) :
                    true;

                // console.log('##role##', x.name);
                // console.log('##userOrgHasPermission##', userOrgHasPermission);
                // console.log('##notBlacklisted##', notBlacklisted);
                // console.log('##whitelistOk##', whitelistOk);
                // console.log('##orgWhitelist##', x.orgWhitelist);

                return userOrgHasPermission && notBlacklisted && whitelistOk;
            })
            .map(x => ({
                label: `${x.group} | ${x.name}`,
                value: x.id
            }));

        // console.log('##final roleGroups##', result);
        return result;
    }, [profile, role, newOrg]);

    // console.log('##roleGroups##', roleGroups);

    const assign = () => {
        //if (meeting)
        //console.log(newType)
        if (newType) {
            toaster(async () => {
                await MemberService.assignRole(
                    role.orgId, id||0 , newOrg|| role.orgId, newType, DTS(newDateFrom as Date), newDateTo ? DTS(newDateTo): undefined
                )
                //console.log(refresh)
                setRefresh && setRefresh((refresh || 0) + 1);

                //console.log( refresh)
                return
            }, {success: 'Funktion hinzugefǔgt', failure: 'Fehler beim hinzufügen!'})
        }
    }

    const doChangeOrg = () => {
        toaster( async() => {
            await MemberService.switchOrg(role?.orgId || 0, member?.orgId || 0, changeOrg || 0, member?.id || 0,undefined )
            setRefresh && setRefresh((refresh || 0) + 1);
        })
    }

    const doTerminateMembership = () => {
        toaster( async() => {
            await MemberService.terminate(role?.orgId || 0, member?.orgId || 0, member?.id || 0, DTS(terminateDate as Date) );
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }

    const doTerminateMembershipDeceased = () => {
        toaster( async() => {
            await MemberService.terminate(role?.orgId || 0, member?.orgId || 0, member?.id || 0, DTS(terminateDate as Date), true );
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }

    const doReactivateMembership = () => {
        toaster( async() => {
            await MemberService.reactivate(role?.orgId || 0, member?.orgId || 0, member?.id || 0 );
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }
    const doAddMembership = () => {
        toaster( async() => {
            await MemberService.assignRoleSimple(role?.orgId || 0,  member?.id || 0, 'MEMBERSHIP');
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }
    console.log('role.org; ',role.orgId);
    //console.log('#profile.organizations#', profile.organizations);
    const filteredOrganizations = useMemo(() => {
        return profile.organizations
            .filter(org => role.orgId === 1 ? true : (org.parent === role.orgId || org.id === role.orgId))
            .sort((a, b) => {
                // First sort by parent
                if (a.orgLevel !== b.orgLevel) {
                    return a.orgLevel - b.orgLevel;
                }
                // Then sort by parent
                if (a.parent !== b.parent) {
                    return a.parent - b.parent;
                }
                // Finally sort by nameShort
                return a.nameShort.localeCompare(b.nameShort);
            })
            .map(x => ({
                label: x.nameShort,
                value: x.id
            }));
    }, [profile.organizations, role.orgId]);
    //console.log('##filteredOrganizations##', filteredOrganizations);

    const handleTerminateMembershipDeceased = () => {
        const isConfirmed = window.confirm("Sind Sie sicher, dass Sie dieses Mitglied als verstorben markieren möchten? Diese Aktion kann nicht rückgängig gemacht werden.");
        if (isConfirmed) {
            doTerminateMembershipDeceased();
        }
    };

    const terminateRole = (roleToEnd: IRole) => {
        const roleKey = `${roleToEnd.id}-${roleToEnd.orgId}-${roleToEnd.dateFrom}`;
        const endDate = roleEndDates[roleKey];

        if (!endDate) {
            toaster(() => Promise.resolve(), {
                failure: 'Bitte wählen Sie ein Enddatum aus'
            });
            return;
        }

        toaster(async () => {
            await MemberService.assignRole(
                role.orgId,
                id || 0,
                roleToEnd.orgId || 0,  // Fix: Added fallback for undefined
                roleToEnd.id,
                DSPg(new Date(roleToEnd.dateFrom)),
                DSPg(endDate)
            );
            setRefresh && setRefresh((refresh || 0) + 1);
        }, {
            success: 'Funktion beendet',
            failure: 'Fehler beim Beenden der Funktion'
        });
    };

    const handleEndDateChange = (date: Date | null, roleKey: string) => {
        setRoleEndDates(prev => ({
            ...prev,
            [roleKey]: date
        }));
    };

    const isEndDateUnchanged = (role: any, roleKey: string) => {
        const newDate = roleEndDates[roleKey];
        const originalDate = role.dateTo ? new Date(role.dateTo) : null;

        if (!newDate) return true;  // Disable if no new date selected
        if (!originalDate) return false;  // Enable if there was no original date

        // Compare just the date parts
        return newDate.toISOString().split('T')[0] === originalDate.toISOString().split('T')[0];
    };


    if (member?.id) {
        return (
            <>
                {member?.verstorben !== 1 /*&& roleGroups.length > 0*/ ? (
                    <ItemNavGroup label={"Neue Funktion"}>
                        <Form>
                            {/*{role.orgId <= 10 ? (*/}
                                <Item size={12} type="full" label={"Organisation (ÖJV/LV/JV)"}>
                                    <Select value={newOrg} options={filteredOrganizations} onChange={setNewOrg}/>
                                </Item>
                            {/*) : ''}*/}
                            <Item size={2} label="Von">
                                <DateInput value={newDateFrom} onChange={setNewDateFrom}/>
                            </Item>
                            <Item size={2} label="Bis">
                                <DateInput value={newDateTo} onChange={setNewDateTo} minDate={newDateFrom as Date}/>
                            </Item>
                            <Item size={8} type={"full"} label="Type">
                                <Select value={newType} options={roleGroups} onChange={setNewType}/>
                            </Item>
                            <Button width={"full"} label={"Hinzufügen"} onClick={assign} />
                        </Form>
                    </ItemNavGroup>
                ) : ''}

                    <ItemNavGroup label={"Mitgliedschaft und Judocard"} className={styles.spacerTop}>
                        {(member?.id && role.orgLevel <= 2 && member.isActive) ?
                            (
                                <Form>
                                    <Item type="full" size={8}>
                                        <Select disabled={false} value={changeOrg} onChange={setChangeOrg}
                                                options={filteredOrganizations} />
                                    </Item>
                                    <Item type="full" size={4} >
                                        <Button //disabled={true}
                                            width={"full"}
                                            //label={"Wechseln 🚧"}
                                            label={"Wechseln"}
                                            onClick={() => doChangeOrg()}
                                            //tooltip="Funktion vorübergehend in Wartung"
                                        />
                                    </Item>
                                </Form>
                            ) :''
                        }
                        <Row type="header">
                            <Item size={1} label="Von"/>
                            <Item size={1} label="Bis"/>
                            <Item size={5} label="Name"/>
                            <Item size={5} label="Org"/>
                        </Row>
                        {filterRolesByGroup(roles, {
                            whitelist: ['Mitgliedschaft', 'Judocard']
                        }).map((role) => {
                            const roleKey = `${role.id}-${role.orgId}-${role.dateFrom}`;
                            return (
                                <Row key={`training-${roleKey}`}>
                                    <Item size={1}>
                                        {FDS(role.dateFrom)}
                                    </Item>
                                    <Item size={1} className={styles.enddateitem}>
                                        {role.dateTo ? (
                                            new Date(role.dateTo).getFullYear() === 2999 ? 'unbefr.' : FDS(new Date(role.dateTo))
                                        ):'--'
                                        }
                                    </Item>
                                    <Item size={5}>
                                        {role.name}<div className={styles.rolegroup}>{role.group ? role.group.String : 'N/A'}</div>
                                    </Item>
                                    <Item size={5}>
                                        {role.orgName}
                                    </Item>
                                </Row>
                            );
                        })}
                    </ItemNavGroup>


                <ItemNavGroup label={"Mitgliedschaft frühestens heute deaktivieren"}  className={styles.spacerTop}>
                    <Form>
                        {member?.verstorben !== 1 ? (
                            <>
                                {(member?.id && role.orgLevel <= 3 && member.isActive) ? (
                                    <>
                                        <Item type={'full'} size={4}>
                                            <DateInput value={terminateDate} onChange={setTerminateDate} minDate={new Date(Date.now())}/>
                                        </Item>
                                        <Item type="full" size={8} >
                                            <Button width={"full"} label={"Mitgliedschaft deaktivieren"} onClick={() => doTerminateMembership()} disabled={terminateDate == null} />
                                        </Item>
                                    </>
                                ) : (
                                    <Item type="full" size={12} >
                                        <Button width={"full"} label={"Mitgliedschaft reaktivieren"} onClick={() => doAddMembership()}  />
                                    </Item>
                                )}

                                <Item type="full" size={6} >
                                    <Button
                                        width={"full"}
                                        label={"Mitglied verstorben"}
                                        onClick={handleTerminateMembershipDeceased}
                                        disabled={false}
                                    />
                                </Item>
                                <Item type="full" size={6} >
                                    <div>ACHTUNG!<br/> Mitglied wird aus ALLEN Funktionen entfernt!</div>
                                </Item>
                            </>
                        ): (<Item type="full" size={12} >
                            <h4>Mitglied ist im System als "verstorben" markiert.</h4>
                        </Item>)}
                    </Form>
                </ItemNavGroup>

                <ItemNavGroup label={"FunktionsRollen & Berechtigungen"} className={styles.spacerTop}>
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={4} label="Name"/>
                        <Item size={4} label="Org"/>
                        <Item size={1} label=""/>
                    </Row>
                    {filterRolesByGroup(roles, {
                        blacklist: ['Mitgliedschaft', 'Systemberechtigungen', 'Judocard']
                    }).map((role) => {
                        const roleKey = `${role.id}-${role.orgId}-${role.dateFrom}`;
                        return (
                            <Row key={`training-${roleKey}`}>
                                <Item size={1}>
                                    {FDS(role.dateFrom)}
                                </Item>
                                <Item size={1} className={styles.enddateitem}>
                                    <DateInput
                                        value={roleEndDates[roleKey] || (role.dateTo ? new Date(role.dateTo) : null)}
                                        onChange={(date) => handleEndDateChange(date, roleKey)}
                                        minDate={new Date()}
                                        maxDate={role.dateTo ? new Date(role.dateTo) : undefined}
                                    />
                                </Item>
                                <Item size={4}>
                                    {role.name}<div className={styles.rolegroup}>{role.group ? role.group.String : 'N/A'}</div>
                                </Item>
                                <Item size={4}>
                                    {role.orgName}
                                </Item>
                                <Item size={1}>
                                    {getOrgLevelFromId(orgId) == OJV ? (<Button
                                        onClick={() => terminateRole(role)}
                                        disabled={isEndDateUnchanged(role, roleKey)}
                                        tooltip={isEndDateUnchanged(role, roleKey) ? undefined: "EndeDatum eingeben"}
                                    ><FontAwesomeIcon
                                            icon={faTrash}
                                        />
                                    </Button>):''}
                                </Item>
                            </Row>
                        );
                    })}
                </ItemNavGroup>
                <ItemNavGroup label={"Systemberechtigungen"} className={styles.spacerTop}>
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={4} label="Name"/>
                        <Item size={4} label="Org"/>
                        <Item size={1} label=""/>
                    </Row>
                    {filterRolesByGroup(roles, {
                        whitelist: ['Systemberechtigungen']
                    }).map((role) => {
                        const roleKey = `${role.id}-${role.orgId}-${role.dateFrom}`;
                        return (
                            <Row key={`training-${roleKey}`}>
                                <Item size={1}>
                                    {FDS(role.dateFrom)}
                                </Item>
                                <Item size={1} className={styles.enddateitem}>
                                    <DateInput
                                        value={roleEndDates[roleKey] || (role.dateTo ? new Date(role.dateTo) : null)}
                                        onChange={(date) => handleEndDateChange(date, roleKey)}
                                        minDate={new Date()}
                                        maxDate={role.dateTo ? new Date(role.dateTo) : undefined}
                                    />
                                </Item>
                                <Item size={4}>
                                    {role.name}<div className={styles.rolegroup}>{role.group ? role.group.String : 'N/A'}</div>
                                </Item>
                                <Item size={4}>
                                    {role.orgName}
                                </Item>
                                <Item size={1}>
                                    {getOrgLevelFromId(orgId) == OJV ? (<Button
                                        onClick={() => terminateRole(role)}
                                        disabled={isEndDateUnchanged(role, roleKey)}
                                        tooltip={isEndDateUnchanged(role, roleKey) ? undefined: "EndeDatum eingeben"}
                                    ><FontAwesomeIcon
                                        icon={faTrash}
                                    />
                                    </Button>):''}
                                </Item>
                            </Row>
                        );
                    })}
                </ItemNavGroup>


{/*                {(member?.id && member?.isActive) ? <Form>
                    <Item type={'full'} size={6}>
                        <DateInput value={terminateDate} onChange={setTerminateDate} minDate={new Date(Date.now())}/>
                    </Item>
                    <Item type="full" size={6} >
                        <Button width={"full"} label={"Mitgliedschaft beenden"} onClick={() => doTerminateMembership()} disabled={terminateDate == null} />
                    </Item>
                </Form>: ''}*/}

                <ItemNavGroup
                    label={"Historie: Judocards"}
                    className={classNames({
                        [styles.history]: true,
                        [styles.spacerTop]: true
                    })}
                >
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={5} label="Name"/>
                        <Item size={5} label="Org"/>
                    </Row>

                    {/*{rolesExpired?.map((role) => (*/}
                    {filterRolesByGroup(rolesExpired, {
                        whitelist: ['JudoCard']
                    }).map((role) => {
                        const roleKey = `${role.id}-${role.orgId}-${role.dateFrom}`;
                        return (
                            <Row key={`training-${roleKey}`}>
                                <Item size={1}>
                                    {FDS(role.dateFrom)}
                                </Item>
                                <Item size={1}>
                                    {role.dateTo ? (
                                        new Date(role.dateTo).getFullYear() === 2999 ? 'unbefristet' : FDS(new Date(role.dateTo))
                                    ):'unbefristet'
                                    }
                                </Item>
                                <Item size={5}>
                                    {role.name}<div className={styles.rolegroup}>{role.group ? role.group.String : 'N/A'}</div>
                                </Item>
                                <Item size={5}>
                                    {role.orgName}
                                </Item>
                            </Row>
                        );
                    })}
                </ItemNavGroup>

                <ItemNavGroup
                    label={"Historie: Mitgliedschaften"}
                    className={classNames({
                        [styles.history]: true,
                        [styles.spacerTop]: true
                    })}
                >
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={5} label="Name"/>
                        <Item size={5} label="Org"/>
                    </Row>

                    {/*{rolesExpired?.map((role) => (*/}
                    {filterRolesByGroup(rolesExpired, {
                        whitelist: ['Mitgliedschaft']
                    }).map((role) => {
                        const roleKey = `${role.id}-${role.orgId}-${role.dateFrom}`;
                        return (
                            <Row key={`training-${roleKey}`}>
                                <Item size={1}>
                                    {FDS(role.dateFrom)}
                                </Item>
                                <Item size={1}>
                                    {role.dateTo ? (
                                        new Date(role.dateTo).getFullYear() === 2999 ? 'unbefristet' : FDS(new Date(role.dateTo))
                                    ):'unbefristet'
                                    }
                                </Item>
                                <Item size={5}>
                                    {role.name}<div className={styles.rolegroup}>{role.group ? role.group.String : 'N/A'}</div>
                                </Item>
                                <Item size={5}>
                                    {role.orgName}
                                </Item>
                            </Row>
                        );
                    })}
                </ItemNavGroup>

                <ItemNavGroup
                    label={"Historie Funktionen"}
                    className={classNames({
                        [styles.history]: true,
                        [styles.spacerTop]: true
                    })}
                >
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={5} label="Name"/>
                        <Item size={5} label="Org"/>
                    </Row>

                    {/*{rolesExpired?.map((role) => (*/}
                    {filterRolesByGroup(rolesExpired, {
                        blacklist: ['Judocard', 'Mitgliedschaft', 'Systemberechtigungen']
                    }).map((role) => {
                        const roleKey = `${role.id}-${role.orgId}-${role.dateFrom}`;
                        return (
                            <Row key={`training-${roleKey}`}>
                                <Item size={1}>
                                    {FDS(role.dateFrom)}
                                </Item>
                                <Item size={1}>
                                    {role.dateTo ? (
                                        new Date(role.dateTo).getFullYear() === 2999 ? 'unbefristet' : FDS(new Date(role.dateTo))
                                    ):'unbefristet'
                                    }
                                </Item>
                                <Item size={5}>
                                    {role.name}<div className={styles.rolegroup}>{role.group ? role.group.String : 'N/A'}</div>
                                </Item>
                                <Item size={5}>
                                    {role.orgName}
                                </Item>
                            </Row>
                        );
                    })}
                </ItemNavGroup>


                <ItemNavGroup
                    label={"Historie: Systemberechtigungen"}
                    className={classNames({
                        [styles.history]: true,
                        [styles.spacerTop]: true
                    })}
                >
                    <Row type="header">
                        <Item size={1} label="Von"/>
                        <Item size={1} label="Bis"/>
                        <Item size={5} label="Name"/>
                        <Item size={5} label="Org"/>
                    </Row>

                    {/*{rolesExpired?.map((role) => (*/}
                    {filterRolesByGroup(rolesExpired, {
                        whitelist: ['Systemberechtigungen']
                    }).map((role) => {
                        const roleKey = `${role.id}-${role.orgId}-${role.dateFrom}`;
                        return (
                            <Row key={`training-${roleKey}`}>
                                <Item size={1}>
                                    {FDS(role.dateFrom)}
                                </Item>
                                <Item size={1}>
                                    {role.dateTo ? (
                                        new Date(role.dateTo).getFullYear() === 2999 ? 'unbefristet' : FDS(new Date(role.dateTo))
                                    ):'unbefristet'
                                    }
                                </Item>
                                <Item size={5}>
                                    {role.name}<div className={styles.rolegroup}>{role.group ? role.group.String : 'N/A'}</div>
                                </Item>
                                <Item size={5}>
                                    {role.orgName}
                                </Item>
                            </Row>
                        );
                    })}
                </ItemNavGroup>
            </>
        );
    } else {
        return <p>Noch nicht verfǔgber</p>
    }
};