import React from 'react';
import styles from './BeltColorStripes.module.scss';

interface BeltColorStripesProps {
    colors: string[];
    height?: string;    // Optional prop to customize height
    border?: string;    // Optional prop to customize border
    width?: string;
    className?: string; // Optional prop for additional styling
}

export const BeltColorStripes: React.FC<BeltColorStripesProps> = ({
    colors,
    height = '1rem',
    border = '1px solid gray',
    width = '100%',
    className
}) => {
    if (!colors || colors.length === 0) return null;

    return (
        <div
            className={className}
            style={{
                display: 'flex',
                height: height,
                width: width,
                border: border
            }}
        >
            {colors.map((color, index) => (
                <div
                    key={index}
                    style={{
                        backgroundColor: color,
                        flex: 1,
                        height: '100%'
                    }}
                />
            ))}
        </div>
    );
};

export default BeltColorStripes;