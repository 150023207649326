import React, {useEffect, useMemo, useState} from 'react';
import image from "../../theme/images/profileImage.png"
import classNames from "classnames";
import styles from './AvatarImage.module.scss'

interface IAvatarImageProps {
    src?: string;
    alt?: string;
    onError?: () => void;
    className?: string;
}

const AvatarImageState: React.FC<IAvatarImageProps> = ({src, alt, onError, className}) => {
    const [active, setActive] = useState(false)
    const [failed, setFailed] = useState(false)

    // Memoize the src to prevent unnecessary reloads
    const imgSrc = useMemo(() => {
        if (!src) return undefined;
        // Add cache buster only when src changes
        const url = new URL(src, window.location.origin);
        if (!url.searchParams.has('v')) {
            url.searchParams.set('v', '1'); // Version parameter for cache control
        }
        return url.toString();
    }, [src]);

    //
    // useEffect(() => {
    //     setFailed(false)
    // }, [src]);
    //
    // const onErrorDefault = () => {
    //     setFailed( true);
    //     onError && onError();
    // }

    useEffect(() => {
        setFailed(false)
        setActive(false)
    }, [imgSrc]); // Only reset on actual source changes

    const onErrorDefault = () => {
        setFailed(true);
        onError && onError();
    }

    return (
        <>
            {(!failed && src) ? (
                    <img
                        className={classNames(className, styles.image, active && styles.active)}
                        src={src} loading="lazy"
                        alt={alt} onError={() => onErrorDefault()}
                        onLoad={(evt) => setActive(true)}
                    />)
                : (<img
                    className={classNames(className, styles.image, active && styles.active)}
                    src={image}
                    loading="lazy"
                    alt={alt}
                    onLoad={(evt) => setActive(true)}
                />)
            }
        </>
    );
}

export default AvatarImageState;
