import JsBarcode from 'jsbarcode';


export class Barcode {
    public static generate(value: string, color: string = '#000', width: number= 140) {
        const xmlSerializer = new XMLSerializer();
        // const document = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null);
        const svgData = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        // fixme just for test
        JsBarcode(svgData, value, {
            xmlDocument: document,
            height: 50,
            fontSize: 13,
            margin: 2,
            width: 2,
            lineColor: color,
        });
        return xmlSerializer.serializeToString(svgData);
    }
}