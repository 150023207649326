import React, { useState, useEffect, useMemo } from 'react';
import { Form, Item, Button, Select, Input, Label } from '../../form';
import { InputArea } from '../../form/InputArea';
import { Upload } from '../../form/Upload';
import { SelectMulti } from '../../form/SelectMulti';
import { ItemNavGroup } from '../../form/ItemNavGroup';
import { INotificationCreate } from '../../../api/models/NotificationModel';
import { NotificationService } from '../../../api/notification';
import { MeetingService } from "../../../api/meeting";
import { useParamId, useParamRole } from '../../useParam';
import { IPageLinkProps } from '../../page.props';
import moment from 'moment';
import styles from './notificationForm.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classNames from "classnames";
import {IFileUpload} from "../../../api/models";
import {toaster} from "../../../core/toaster";
import {UtilService} from "../../../api";
import { MetaService } from '../../../api/meta';
import news from "../../../pages/desktop/news";

export const NotificationForm: React.FC<IPageLinkProps> = ({ user, profile, refresh, setRefresh, gotoUrl }) => {
    const [orgIds, setOrgIds] = useState<number[]>([]);
    const [meetingIds, setmeetingIds] = useState<number[]>([]);
    const [meeting, setMeeting] = useState<any[]>([]); // Add this state for events
    const [roles, setRoles] = useState<string[]>([]);
    const [mediaType, setMediaType] = useState<string>('email');
    const [title, setTitle] = useState('');
    const [pushMessage, setPushMessage] = useState('');
    const [content, setContent] = useState('');
    const role = useParamRole(user);
    const [paType, setPaType] = useState<string[]>(['email', 'push']);
    const notificationTypeOptions = [
        { label: 'Email', value: 'email' },
        { label: 'Push', value: 'push' }
    ];

    //--ReactQuill
    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ],
    };
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
    ];
    //--
    const EXCLUDE_GROUP_IDS = [1, 6, 28];


    // Transform profile organizations for SelectMulti
    const orgOptions = React.useMemo(() =>
        profile.organizations.map((org: any) => ({
            label: org.nameShort,
            value: org.id
        })), [profile.organizations]
    );

    useEffect(() => {
        const fetchEvents = async () => {
            if (role?.orgId) {
                try {
                    const today = moment().format('YYYYMMDD');
                    const threeYearsFromNow = moment().add(36, 'month').format('YYYYMMDD');

                    const meetingData = await MeetingService.all(
                        role.orgId,
                        today,
                        threeYearsFromNow
                    );

                    // Add null check before sorting and mapping
                    if (meetingData && Array.isArray(meetingData)) {
                        // Sort meetings by startdate in ascending order
                        const sortedMeetings = [...meetingData].sort((a, b) =>
                            moment(a.startdate).valueOf() - moment(b.startdate).valueOf()
                        );

                        const meetingOptions = sortedMeetings.map(meeting => {
                            const mainLine = `${meeting.id} | ${meeting.name}`;
                            const dateLine = `${moment(meeting.startdate).format('DD.MM.YYYY')} - ${moment(meeting.enddate).format('DD.MM.YYYY')}`;
                            const registrationLine = `Anmeldung: ${meeting.open_from ? moment(meeting.open_from).format('DD.MM.YYYY') : '--'} - ${meeting.open_to ? moment(meeting.open_to).format('DD.MM.YYYY') : '--'}`;

                            return {
                                label: (
                                    <div>
                                        <div className={styles['notification-form__event-line--main']}>
                                            {mainLine}
                                        </div>
                                        <div className={styles['notification-form__event-line--details']}>
                                            {dateLine} | {registrationLine}
                                        </div>
                                    </div>
                                ),
                                value: meeting.id
                            };
                        });

                        setMeeting(meetingOptions);
                    } else {
                        setMeeting([]); // Set empty array if no data
                    }
                } catch (error) {
                    console.error('Failed to fetch events:', error);
                    setMeeting([]); // Set empty array on error
                }
            }
        };
        fetchEvents();
    }, [role?.orgId]);


    // Meeting roles for SelectMulti
    const roleOptions = useMemo(() => {
        if (!profile.roleGroups) return [];

        return profile.roleGroups
            .filter(group => !EXCLUDE_GROUP_IDS.includes(group.groupId))
            .flatMap((group: any) =>
                (group.roles || []).map((role: any) => ({
                    label: `${group.groupName || 'Unknown Group'} | ${role.name || 'Unknown Role'}`,
                    value: role.name,
                    groupName: group.groupName || 'Unknown Group',
                    roleName: role.name || 'Unknown Role'
                }))
            )
            .sort((a, b) => {
                // First sort by group name
                const groupCompare = a.groupName.localeCompare(b.groupName);
                if (groupCompare !== 0) return groupCompare;
                // Then by role name
                return a.roleName.localeCompare(b.roleName);
            });
    }, [profile.roleGroups]);

    console.log('++profile.roleGroups',profile.roleGroups)



    const handleSubmit = async () => {
        try {
            const notificationData: INotificationCreate = {
                title,
                content,
                paType: mediaType === 'both' ? ['email', 'push'] : [mediaType],
                paOrganizations: orgIds,
                paEvents: meetingIds.length > 0 ? meetingIds : undefined,
                paRoles: roles.map(role => Number(role)),
                attachedFiles: [],
                lang: 'de',
            };

            const newsId = await NotificationService.create(role.orgId, notificationData);
            if (setRefresh) {
                setRefresh((refresh || 0) + 1);
            }
        } catch (error) {
            console.error('Failed to create notification:', error);
        }
    };

    // const handleFileUpload = (newFile: IFileUpload) => {
    //     if (meeting) {
    //         const updatedMeeting = {
    //             ...meeting,
    //             fileUploads: [...(news.fileUploads || []), newFile]
    //         };
    //
    //         setMeeting(updatedMeeting);
    //     }
    // };
    //
    //
    // const handleFileDelete = (fileToDelete: any) => {
    //     if (meeting) {
    //         toaster(async () => {
    //             await UtilService.DeleteFile(role.orgId,fileToDelete.name);
    //         }, {success: 'Event erfolgreich gelöscht', failure: 'Fehler beim Löschen der Datei!'})
    //
    //         const updatedMeeting = {
    //             ...meeting,
    //             fileUploads: (meeting.fileUploads || []).filter(file => file !== fileToDelete)
    //         };
    //         setMeeting(updatedMeeting);
    //     }
    // };

    return (
        <ItemNavGroup label="Neuen Nachricht erstellen">
            <Form>
                <Item size={12} type="full" label="Organisationen">
                    <SelectMulti
                        options={orgOptions}
                        value={orgIds}
                        onChange={setOrgIds}
                        type="number"
                    />
                </Item>

                <Item size={12} type="full" label="Events">
                    <Select
                        options={meeting}
                        value={meetingIds}
                        onChange={setmeetingIds}
                        type="number"
                        placeholder="Wähle ein Event aus"
                        freeHeight={true}
                        className={styles.notificationFormSelect}
                    />
                </Item>

                <Item size={12} type="full" label="Rollen">
                    <SelectMulti
                        options={roleOptions}
                        value={roles}
                        onChange={setRoles}
                    />
                </Item>

                <Item size={12} type="full" label="Medientyp">
                    <SelectMulti
                        options={notificationTypeOptions}
                        value={paType}
                        onChange={setPaType}
                        type="text"
                        className={styles.select}
                    />
                </Item>

                <Item size={12} type="full" label="Email-Betreff bzw. Push-Messagetitel (am besten < 100 Zeichen)">
                    <Input
                        value={title}
                        onChange={setTitle}
                        className="form-control"
                        maxLength={250}
                        required={true}
                    />
                    <div
                        className={classNames(
                            styles.characterCount,
                            {
                                [styles['characterCount--warning']]: title.length > 100,
                                [styles['characterCount--error']]: title.length > 249
                            }
                        )}
                    >
                        {title.length} / 100 Zeichen (empfohlen:100, max: 250)
                    </div>
                </Item>

                <Item size={12} type="full" label="Zusammenfassung bzw. Inhalt der Push-Nachricht">
                    <InputArea
                        value={pushMessage}
                        onChange={setPushMessage}
                        className="form-control"
                        rows={3}
                        required={true}
                        maxLength={1020}
                    />
                    <div
                        className={classNames(
                            styles.characterCount,
                            {
                                [styles['characterCount--green']]: pushMessage.length < 61,
                                [styles['characterCount--warning']]: pushMessage.length > 180,
                                [styles['characterCount--error']]: pushMessage.length > 1020
                            }
                        )}
                    >
                        {pushMessage.length} / 180 Zeichen (empfohlen: 50-60, {`<`} 180 Zeichen für direkte Sichtbarkeit, max: 1020)
                    </div>
                </Item>

                <Item size={12} type="full" label="Inhalt (nur Email), wird bei Auswahl der Pushnachricht in der App    geöffnet">
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        className={styles.editor}
                    />
                </Item>

                 {/*<Item type="full" label="Anhänge" size={12}>*/}
                 {/*       <ul>*/}
                 {/*           {meeting_fileUploads.map((file, index) => (*/}
                 {/*               <li key={index}>*/}
                 {/*                       /!*<FontAwesomeIcon icon={faTrash} />*!/*/}
                 {/*                   <Label>*/}
                 {/*                       <a href={MetaService.resolveCompetitionFileUrlAWS( file.name)}*/}
                 {/*                          target="_blank" download>{file.original}</a>*/}
                 {/*                   </Label>*/}
                 {/*                   <Button label={'Löschen'} onClick={()=>handleFileDelete(file)} className={styles.delete} ></Button>*/}
                 {/*               </li>*/}
                 {/*           ))}*/}
                 {/*       </ul>*/}
                 {/*       <Upload*/}
                 {/*           orgId={role?.orgId}*/}
                 {/*           purpose="meeting"*/}
                 {/*           purposeId={meetingId}*/}
                 {/*           onUploadComplete={(uploadedFile) => handleFileUpload(uploadedFile)}*/}
                 {/*       />*/}
                 {/*   </Item>*/}

                <Button
                    width="full"
                    label="Nachricht erstellen"
                    onClick={handleSubmit}
                />
            </Form>
        </ItemNavGroup>
    );
};