import React, {useEffect, useState, useMemo, useCallback} from "react";import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import {FD, Item, DateInput, Input} from "../../form";
import {faAdd, faPenToSquare, faSquareCheck, faList, faEllipsis, faBan} from "@fortawesome/free-solid-svg-icons";
import {faSquare as faSquareRegular} from "@fortawesome/free-regular-svg-icons";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import classNames from "classnames";
import {useParamId, useParamRole} from "../../useParam";
import {ELicenceStatus, ILicenceOrder} from "../../../api/models";
import {IPageLinkProps} from "../../page.props";
import moment from 'moment';
import styles from './licence.module.scss';
import {LicenceService} from "../../../api/licence";
import printStyles from "../../../theme/print.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface LicenceProps extends IPageLinkProps {
    refresh: number;
    onOrdersFiltered?: (orders: ILicenceOrder[], from: Date|null, to: Date|null) => void;  // Make optional and match Kyu pattern
}

const Licence: React.FC<LicenceProps> = ({
                                             user,
                                             profile,
                                             gotoUrl,
                                             refresh,
                                             onOrdersFiltered
                                         }) => {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState<ILicenceOrder[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Define fixed min and max dates
    const minAllowedDate = moment('2007-01-01').toDate();
    const maxAllowedDate = moment().add(1, 'month').toDate();

    const id = useParamId();
    const role = useParamRole(user);

    // Set initial states with defaults within allowed range
    const [dateFrom, setDateFrom] = useState<Date|null>(moment().subtract(1, 'year').toDate());
    const [dateTo, setDateTo] = useState<Date|null>(moment().add(1, 'month').toDate());

    // Filter orders based on date range and search term
    const filteredOrders = useMemo(() => {
        return orders.filter(order => {
            // Date filter
            const orderDate = order.created ? new Date(order.created) : null;
            const matchesDate = orderDate && dateFrom && dateTo ?
                orderDate >= dateFrom && orderDate <= dateTo :
                true;

            // Search filter
            const matchesSearch = !searchTerm ||
                order.id.toString().includes(searchTerm) ||
                order.orgName?.toLowerCase().includes(searchTerm.toLowerCase());

            return matchesDate && matchesSearch;
        });
    }, [orders, dateFrom, dateTo, searchTerm]);

    useEffect(() => {
        if (onOrdersFiltered && !loading) {  // Add loading check
            onOrdersFiltered(filteredOrders, dateFrom, dateTo);
        }
    }, [filteredOrders, dateFrom, dateTo, onOrdersFiltered]); // Remove onOrdersFiltered from dependencies

    useEffect(() => {
        setLoading(true);
        if (role != null) {
            LicenceService.all(role.orgId).then((o) => {
                setOrders(o);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [user, refresh, role]);


    return <>
        <div className={printStyles['hide-for-print']}>
        <ItemNavGroup label={role?.orgName} className={styles.leftmenue}>
            <div className={styles.search}>
                <Input
                    placeholder="Suche nach Bestell-Nr. oder Verein..."
                    value={searchTerm}
                    onChange={setSearchTerm}
                    className={styles.searchInput}
                />
            </div>

            <div className={styles.dateFilter}>
                <div className={styles.dateInputs}>
                    <div className={styles.dateInput}>
                        <label>Von:</label>
                        <DateInput
                            value={dateFrom}
                            onChange={(date: Date | null) => setDateFrom(date)}
                            minDate={minAllowedDate}
                            maxDate={dateTo || new Date()}
                            placeholderText="Von Datum..."
                        />
                    </div>
                    <div className={styles.dateInput}>
                        <label>Bis:</label>
                        <DateInput
                            value={dateTo}
                            onChange={(date: Date | null) => setDateTo(date)}
                            minDate={dateFrom || minAllowedDate}
                            maxDate={maxAllowedDate}
                            placeholderText="Bis Datum..."
                        />
                    </div>
                </div>
            </div>

            <ItemNav
                align="center"
                label="Neue JUDOCARDs bestellen"
                className={styles.add}
                icon={faAdd}
                href={`${gotoUrl}/?t=new`}
            />

            {/* Use filteredOrders instead of orders for the listing */}
            {filteredOrders?.length > 0 ? filteredOrders?.sort((a, b) => b.id - a.id).map(e => (
                <ItemNav
                    key={`order-${e.id}`}
                    href={`${gotoUrl}/${e.id}?t=candidates`}
                    className={classNames(
                        styles.entry,
                        {
                            [styles.selected]: id === e.id,  // Add this line
                            [styles.submitted]: e.status === 'submitted' && !e.kyuExamId,
                            [styles['submitted-by-kyu']]: e.status === 'submitted' && e.kyuExamId,
                            [styles.approved]: e.status === 'approved',
                            [styles.created]: e.status === 'created'
                        }
                    )}
                >
                    <div className={styles.entry}>
                        <div className={styles.date}>
                            {FD(e.created)}
                            {/*<div className={styles.nr}>Order-Nr. {e.id}</div>*/}
                            <div className={styles.org}>{e.orgName}
                                <div className={styles.creator}>{e.creator}</div>
                            </div>
                        </div>
                        <div className={styles.name}>
                            <div className={styles.info}>
                                <div className={classNames(styles.status)}>
                                    {translateStatus(e.status, e.id, e.kyuExamId)}
                                </div>
                                <div className={styles.kyupruefung}>
                                    {e.kyuExamId ? 'KYU: ' + e.kyuExamId : null}
                                </div>
                            </div>
                            <div className={styles.judocardGroup}>
                                <div className={styles.judocard}>{e?.years[0]}</div>
                                <div className={styles.amount}>{e.items} Stück</div>
                            </div>
                        </div>
                    </div>
                </ItemNav>
            )) : (
                <Item type="list" label={searchTerm || dateFrom || dateTo ?
                    "Keine Ergebnisse gefunden" :
                    "Keine Bestellungen verfügbar"}
                />
            )}
        </ItemNavGroup>
        </div>
    </>;
};

const translateStatus = (status: ELicenceStatus, id: number, kyuExamId: number | null | undefined) => {
    switch (status) {
        case "canceled":
            return <><FontAwesomeIcon icon={faBan}  /> {id}</>;
        case "created":
            return <span className={styles.draftIcon}><FontAwesomeIcon icon={faPenToSquare}  /> {id}</span>;
        case "submitted":
            return <span className={styles.submittedIcon}><FontAwesomeIcon icon={faSquareRegular as IconProp}  /> {id}</span>;
        case "approved":
            return <span className={styles.greenIcon}><FontAwesomeIcon icon={faSquareCheck} /> {id}</span>;
        default:
            return "";
    }
}

export default Licence;