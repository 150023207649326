import React, {useEffect, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {useParams} from "react-router";
import {EKyuExamStatus, IKyuChairMember, IOrgFunction} from "../../../api/models";
import {useNavigate} from "react-router-dom";
import {KyuService} from "../../../api";
import {BackButton, Button, DateInput, DS, Form, Input, Item} from "../../form";
import {toaster} from "../../../core/toaster";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {addMonths} from "date-fns";
import SelectKyuBordMembers from "../../tournaments/SelectKyuChairMembers";
import styles from "./kyu_detail_new.module.scss";

import { addDays, subDays } from 'date-fns';
import printStyles from "../../../theme/print.module.scss";

interface ExtendedPageLinkProps extends IPageLinkProps {
    triggerRefresh: () => void;
}

export const KyuDetailNew: React.FC<ExtendedPageLinkProps> = ({ user, profile, gotoUrl, refresh, setRefresh, triggerRefresh }) => {
    const params = useParams();
    const [date, setDate] = useState<Date | null>(new Date(Date.now()));
    const [name, setName] = useState<string | null>(null);
    const [chairMemberMain, setChairMemberMain] = useState<number | null>(null);
    const [chairMember1, setChairMember1] = useState<number | null>(null);
    const [chairMember2, setChairMember2] = useState<number | null>(null);
    const [orgChairMembers, setOrgChairMembers] = useState<IKyuChairMember[]>([]);
    const navigate = useNavigate();

    const roleId = Number(params['role'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    useEffect(() => {
        if (date && role?.orgId) {
            KyuService.listChairMembers(role?.orgId, {
                search: '',
                date: DS(date as Date)
            }).then(setOrgChairMembers)
        }
    }, [date, role?.orgId]);


    const validate = () => {
        return date != null && chairMemberMain != null ;
    }

    const create = async (data: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        if (validate()) {
            toaster(async () => {
                const id = await KyuService.create(role?.orgId, {
                    date: DS(date as Date),
                    name: name as string,
                    examChairMember: {
                        vorsitzer: chairMemberMain as number,
                        beisitzer1: chairMember1 as number,
                        beisitzer2: chairMember2 as number,
                    }
                });
                console.log('createdExamId: ', id);
                if (id > 0) {
                    console.log('id: ', id);
                    setRefresh && setRefresh((prevRefresh) => prevRefresh + 1);
                    triggerRefresh(); // Call this to update Kyu component

                    navigate(`${gotoUrl}/${id}?t=candidates`);

                    const url = new URL(window.location.href);
                    url.searchParams.set('t', 'candidates');
                    window.history.replaceState(null, '', url);

                    // Set a slight delay before reloading to ensure navigation completes
                    setTimeout(() => {
                            window.location.reload();
                        }, 100);
                }else{
                    console.error('Failed to create exam');
                }


                return
            }, {success: 'Kyu Prüfung erfolgreich angelegt', failure: 'Fehler beim Anlegen der Prüfung!'})
        }
        return false;
    }

    const today = new Date();
    const minDate = subDays(today, 14);
    const maxDate = addDays(today, 14);

    console.log("minDate-maxDate", minDate, '-', maxDate);
    return <>
        <div className={printStyles['hide-for-print']}>
            <BackButton label={'Prüfung verlassen'} navigateTo={gotoUrl}/>
        </div>
        <ItemNavGroup label="Prüfung anlegen">
            <Form>
                <Item type="full" label="Datum" size={6}>
                    {/*<DateInput*/}
                    {/*    value={date}*/}
                    {/*    onChange={setDate}*/}
                    {/*    minDate={addMonths(new Date(), -1)}*/}
                    {/*    maxDate={addMonths(new Date(), 3)}*/}
                    {/*/>*/}
                    <DateInput
                        value={date}
                        onChange={setDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={false}
                    />
                </Item>
                <Item type="full" label="Ort (optional)" size={6}>
                    <Input
                        value={name}
                        onChange={(v) => setName(v)}
                    />
                </Item>
                <Item type="full" label="Vorsitz">
                    <SelectKyuBordMembers
                        orgId={role?.orgId}
                        id={chairMemberMain}
                        setId={setChairMemberMain}
                        orgMembers={orgChairMembers}
                        date={date}
                        disabled={date == null}
                        exclude={[chairMember1 || 0, chairMember2 || 0]}
                    />
                </Item>
                <Item type="full" label="Beisitz 1">
                    <SelectKyuBordMembers
                        orgId={role?.orgId}
                        id={chairMember1}
                        setId={setChairMember1}
                        orgMembers={orgChairMembers}
                        date={date}
                        disabled={date == null || chairMemberMain == null}
                        exclude={[chairMemberMain || 0, chairMember2 || 0]}
                    />
                </Item>
                <Item type="full" label="Beisitz 2">
                    <SelectKyuBordMembers
                        orgId={role?.orgId}
                        id={chairMember2}
                        setId={setChairMember2}
                        orgMembers={orgChairMembers}
                        date={date}
                        disabled={date == null || chairMember1 == null}
                        exclude={[chairMemberMain || 0, chairMember1 || 0]}
                    />
                </Item>
            </Form>
        </ItemNavGroup>
        <Button width={'full'} label="Speichern"   onClick={create} disabled={!validate()}/>
    </>
};