import React, { useEffect, useState } from 'react';
import {toaster, toastError} from "../../../core/toaster";
import { IPageLinkProps } from "../../page.props";
import { useNavigate } from "react-router-dom";
import {IMeeting, IMeetingRole, IMeetingTypes, IMeetingCandidatesAll} from "../../../api/models";
import { MeetingService } from "../../../api/meeting";
import { Loading } from "../../foundation/Loading";
import { ItemNav } from "../../form/ItemNav";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { useParamId, useParamRole } from "../../useParam";
import {Button, Input, Item, Select, FD, Form, Segment, Label} from "../../form";
import {SelectMulti} from "../../form/SelectMulti";
import {InputArea} from "../../form/InputArea";
import styles from './event_detail.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import {getOrgMotherId} from "../../../util/roles";
import {MetaService} from "../../../api";
import {Upload} from "../../form/Upload";
import Papa from 'papaparse';

const convertToCSV = (participants: IMeetingCandidatesAll[]): string => {
    const headers = [
        'JAMA ID',
        'JudoManager ID',
        'Familienname',
        'Vorname',
        'Geburtsdatum',
        'Alter Ende Jahr',
        'Geschlecht',
        'IOC',
        'Lizenz B',
        'Nation überprüft',
        'Medical',
        'Ausbildung Beschreibung',
        //'Ausbildung Name',
        'Rolle',
        'Org ID',
        'für Verein',
        'Landesverband',
        'Altersklasse',
        'Gewichtsklasse',
        'Einladung gültig bis',
        'Status'
    ];

    const formatGender = (gender: 'm' | 'w' | 'd' | 'x'): string => {
        const genderMap: Record<string, string> = {
            'm': 'männlich',
            'w': 'weiblich',
            'd': 'divers',
            'x': 'keine Angabe'
        };
        return genderMap[gender] || gender;
    };

    const data = participants.map(p => ({
        'JAMA ID': p.jamaId,
        'JudoManager ID': p.judoManagerId,
        'Familienname': p.familyName,
        'Vorname': p.firstName,
        'Geburtsdatum': p.born,
        'Alter Jahresende': p.ageEndOfYear,
        'Geschlecht': formatGender(p.gender),
        'Altersklasse': p.AK,
        'Gewichtsklasse': p.GK,
        'IOC': p.ioc,
        'Lizenz B': p.licenseB,
        'Nationalität überprüft': p.nationChecked,
        'Medical': p.medical,
        'Grad': p.ausbDesc,
        //'Ausbildung Name': p.ausbName,
        'Rolle': p.role,
        'Org ID': p.orgId,
        'für Verein': p.role?.toLowerCase() === 'judoka' ? p.orgName : '--',
        'Landesverband': p.LV,
        'Einladung gültig bis': p.invitationTimeoutDate,
        'Status': p.status
    }));

    return Papa.unparse(data, {
        quotes: true,  // Quote all fields
        delimiter: ';', // Use semicolon for German Excel compatibility
        header: true,
    });
};

const handleExportCSV = async (orgId: number, meetingId: number, meetingName: string) => {
    try {
        // Get all candidates using the candidates_all method
        const allCandidates = await MeetingService.candidates_all(orgId, meetingId, true);

        // Convert the data to CSV
        const csv = convertToCSV(allCandidates);

        // Create and trigger download
        const blob = new Blob(['\ufeff' + csv], {
            type: 'text/csv;charset=utf-8;'
        });

        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', `Event_${meetingId}_Teilnehmer_${new Date().toISOString().split('T')[0]}.csv`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Export failed:', error);
        toastError("Export fehlgeschlagen");
    }
};

export const EventDetailRoles: React.FC<IPageLinkProps> = ({ user, profile, gotoUrl }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState<IMeeting | null>(null);

    const examId = useParamId();
    const role = useParamRole(user);

    const meeting_fileUploads = meeting?.fileUploads || [];

    useEffect(() => {
        if (role?.orgId) {
            MeetingService.get(role.orgId, examId || 0).then((m) => {
                setMeeting(m);
                setLoading(false);
            });
        }
    }, [examId, role]);

    if (loading) {
        return <div className={styles.loading}><Loading/></div>;
    }

    const editable = (
        meeting?.org_id === role.orgId ||
        role.orgId === 1 ||
        (meeting?.org_id && getOrgMotherId(meeting.org_id, profile.organizations) === role.orgId)
    )


    const meetingRoles = meeting?.param?.MEETINGROLES_REQ ||
        JSON.parse(meeting?.param?.MEETINGROLES || '[]') as number[];

    const filteredRoles = meetingRoles.filter(x => (meeting?.org_id === role.orgId || meeting?.org_mutter_id === role.orgId || 1=== role.orgId) || x === 9);

    function getMeetingTypeNameById(meetingTypes: IMeetingTypes[], id: number): string | undefined {
        const meetingType = meetingTypes.find(mt => mt.id === id);
        return meetingType?.name;
    }


    let meetingTypeName;
    if (Array.isArray(profile.meetingTypes) && meeting?.meetingtype_id !== undefined && typeof meeting.meetingtype_id === 'number') {
        meetingTypeName = getMeetingTypeNameById(profile.meetingTypes, meeting.meetingtype_id);
    } else {
        meetingTypeName = 'Meeting Type not found';
    }

    const isRoleSelectEnabled = !(
        meeting?.open_from && meeting?.open_to && new Date(meeting.open_from) <= new Date() && new Date(meeting.open_to) >= new Date() ||
        (meeting?.org_id === role.orgId ||
            role.orgId === 1 ||
            (meeting?.org_id && getOrgMotherId(meeting.org_id, profile.organizations) === role.orgId))
    );

    return (
        <>
            {
                //console.log('editable: ', meeting?.org_id, role.orgId, meeting?.org_id === role.orgId )
                ///console.log('profile.meetingTypes: ', profile?.meetingTypes)
            }

                <ItemNavGroup label={`${meeting?.name || 'Ohne Namen'}`}>
                <Form>
                    <Item type="full" label="Veranstalter" size={6}>
                        <div className={styles.children}>{Array.isArray(profile.organizations) ?
                            profile.organizations.find(org => org.id === meeting?.org_id)?.nameShort : ''}

                        </div>
                    </Item>
                    <Item type="full" label="Meeting Type" size={6}>
                        <div className={styles.children}>
                            {meetingTypeName}
                        </div>
                    </Item>
                    <Item type="full" label="Altersklassen" size={12}>
                        <div className={styles.children}>
                            {/*{meeting.param?.ty || ' '}*/}
                            <div>{meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object' && Array.isArray(meeting.ages_nennbar.m)
                            && (meeting.ages_nennbar.m).length > 0 ? `M: ${meeting.ages_nennbar.m.join(', ')}` : 'keine AK für Herrn definiert'}</div>
                            <div>{meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object'
                            && Array.isArray(meeting.ages_nennbar.w) && (meeting.ages_nennbar.m).length > 0 ? `W: ${meeting.ages_nennbar.w.join(', ')}` : 'keine AK für Damen definiert'}</div>
                        </div>
                    </Item>
                    <Item type="full" label="Nennungzeitraum" size={6}>
                        <div className={styles.children}>{FD(meeting?.open_from)} - {FD(meeting?.open_to)}</div>
                    </Item>
                    <Item type="full" label="Event Datum" size={6}>
                        <div className={styles.children}>{FD(meeting?.startdate)} - {FD(meeting?.enddate)}</div>
                    </Item>

                    <Item type="full" label="Ort" size={12}>
                        <div className={styles.children}>{meeting?.param?.ORT || ''}</div>
                    </Item>

                    <Item type="full" label="Nationencheck?" size={6}>
                        <div className={styles.children}>{meeting?.param?.NATIONENCHECK ? "JA" : "Nein"}</div>
                    </Item>
                    <Item type="full" label="Bundesweite Nennung?" size={6}>
                        <>
                            {/*{console.log('meeting?.param?.BUNDESWEIT: ', meeting?.param?.BUNDESWEIT)}*/}
                        </>
                        <div
                            className={styles.children}>{meeting?.param?.BUNDESWEIT === true ? "JA" : meeting?.param?.BUNDESWEIT === false ? "Nein" : '1x speichern!'}</div>
                    </Item>

                    <Item type="full" label="Url" size={6}>
                        <div className={styles.children}>{(meeting?.param?.URL || ' ')}</div>

                    </Item>
                    <Item type="full" label="Streaming Url" size={6}>
                        <div className={styles.children}>{(meeting?.param?.STREAMING_URL || ' ')}</div>

                    </Item>

                    {/*<Item type="full" label="Teilnahme" size={6}>*/}
                    {/*    <div className={styles.children}>{meeting?.param?.TEILNAHME || ' '}</div>*/}
                    {/*</Item>*/}
                    <Item type="full" label="Anmerkungen">
                        <div className={styles.children}>{meeting?.param?.TEXT || ' '}</div>
                    </Item>
                    <Item type="full" label="Preis" size={6}>
                        <div className={styles.children}>{meeting?.param?.PRICING || ' '}</div>
                    </Item>
                    <Item type="full" label="veröffentlichen?" size={6}>
                        <div className={styles.children}>{meeting?.param?.PUBLISH_API ? "JA" : "Nein"}</div>
                    </Item>


                    <Item type="full" label="Anhänge/Auschreibung" size={12}>
                        <ul className={styles.ausschreibung}>
                            {meeting_fileUploads.map((file, index) => (
                                <li key={index}>
                                    {/*<FontAwesomeIcon icon={faTrash} />*/}
                                    <Label>
                                        <FontAwesomeIcon icon={faFileArrowDown} /><a href={MetaService.resolveCompetitionFileUrlAWS( file.name)}
                                           target="_blank" download>{file.original}</a>
                                    </Label>
                                </li>
                            ))}
                        </ul>
                    </Item>
                </Form>

            </ItemNavGroup>

            <div className={styles.headerControls}>
                    {editable &&
                        (
                            <ItemNav
                                className={styles.button}
                                primary={true}
                                label={`Event Nr. ${examId} bearbeiten`}
                                icon={faEdit}
                                href={`${gotoUrl}/${examId}?t=edit`}
                                type={"list"}
                            />
                        )
                    }
                    <ItemNav
                        className={styles.button}
                        onClick={() => {
                            if (role?.orgId && examId) {
                                handleExportCSV(role.orgId, examId, meeting?.name || 'Meeting');
                            } else {
                                toaster(() => {
                                    throw new Error("Fehlende Daten");
                                }, {
                                    failure: "Export nicht möglich - fehlende Daten"
                                });
                            }
                        }}
                        primary={true}
                        label="CSV-Export           "
                        icon={faFileExport}
                        type={"list"}
                    />
            </div>
            <ItemNavGroup label='Anmeldung' className={styles.anmeldung}>
                <>{isRoleSelectEnabled && (
                    <ItemNav className={styles.info}
                             label={`Anmeldung bereits geschlossen. Für eine Übersicht der genannten JudoCards wähle "Judoka".`}/>
                )}</>
                {
                    filteredRoles.length > 0 ? filteredRoles.map(mr => {
                        const meetingRole = profile.meetingRoles.find(x => x.id === mr);

                        if (meetingRole) {
                            if (meetingRole.name == 'Judoka') {
                                if (meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object' &&
                                    (
                                        Array.isArray(meeting.ages_nennbar.m) && (meeting.ages_nennbar.m).length == 0
                                        && Array.isArray(meeting.ages_nennbar.w) && (meeting.ages_nennbar.m).length == 0
                                    )) {
                                    return <ItemNav
                                        className={styles.missingAk}
                                        key={meetingRole.name}
                                        type="list"
                                        label={`${meetingRole?.name}: Der Veranstalter hat noch keine Altersklassen definiert.`}
                                        disabled={false}
                                    />;
                                } else {
                                    return <ItemNav
                                        key={meetingRole.name}
                                        type="list"
                                        label={meetingRole?.name}
                                        //{`${typeof meetingRole?.param?.funk_id === 'string' && meetingRole?.param?.funk_id.length > 0 ? 'funk-id' : 'none'} ${typeof meetingRole?.param?.ausb_id === 'string' && meetingRole?.param?.ausb_id.length > 0 ? 'ausb-id' : 'none'}`}
                                        onClick={() => navigate(`${gotoUrl}/${meeting?.id}?mr=${mr}&t=candidates`)}
                                        disabled={false}
                                    />;
                                }
                            } else {
                                return <ItemNav
                                    key={meetingRole.name}
                                    type="list"
                                    label={meetingRole?.name}
                                    onClick={() => navigate(`${gotoUrl}/${meeting?.id}?mr=${mr}&t=candidates`)}
                                    disabled={false}
                                />;
                            }
                        } else {
                            return <ItemNav key={mr} label={`MeetingRoleId ${mr} not available`}/>;
                        }
                    }) : (
                        meeting?.param?.BUNDESWEIT === true ? (
                            <ItemNav label="Bundesweite Nennung innerhalb des Nennzeitraums. Für diese Veranstaltung sind entweder noch keine Rollen angelegt
                                oder eine Anmeldung über JAMA ist nicht vorgesehen."/>
                        ) : <ItemNav
                            label="Noch nicht als Bundesweit gespeichern. Bitte Event im neuen JAMA einmalig speichern."/>
                    )}

            </ItemNavGroup>

        </>
    );
};
