import {AuthService} from "./auth";
import {HttpService} from "./http";
import {IAdminOrganization} from "./models";
import {Constants} from "./constants";

const GET_ORGANIZATIONS= `${Constants.JAMA_SERVER_API_V2}/admin/:org/organizations`;
const GET_ORGANIZATION = `${Constants.JAMA_SERVER_API_V2}/admin/:org/organization/`
const PUT_ORGANIZATION = `${Constants.JAMA_SERVER_API_V2}/admin/:org/organization`
const POST_ORGANIZATION = `${Constants.JAMA_SERVER_API_V2}/admin/:org/organization`
const R = (r: string, org: number) => r.replace('/:org/', `/${org}/`);

export class OrganizationService {
    public static async all(org: number): Promise<IAdminOrganization[]> {
        // await AuthService.refreshJwt();
        return await HttpService.get<IAdminOrganization[]>(`${GET_ORGANIZATIONS}`.replace('/:org/', `/${org}/`), true, false);
    }


    public static async get(org: number, id: number): Promise<IAdminOrganization> {
        // await AuthService.refreshJwt();
        return await HttpService.get<IAdminOrganization>(R(`${GET_ORGANIZATION}${id}`, org), true);
    }

    public static async update(org: number, id: number, data: IAdminOrganization): Promise<IAdminOrganization> {
        // await AuthService.refreshJwt();
        if (org === id) {
            return await HttpService.put<IAdminOrganization>(R(`${PUT_ORGANIZATION}`, org), data);
        } else {
            return await HttpService.put<IAdminOrganization>(R(`${PUT_ORGANIZATION}/${id}`, org), data);
        }
    }

    public static async create(org: number, data: IAdminOrganization): Promise<IAdminOrganization> {
        // await AuthService.refreshJwt();
        return await HttpService.post<IAdminOrganization>(R(POST_ORGANIZATION, org), data)
    }
}